.projects-page{
	position: relative;
}
.progress{
	position: sticky;
	top: 0;
	left: 0;
	padding-top: 15px;
	text-align: center;
	color: orange;
	font-size: 23px;
	line-height: 1.5;
}
.progress-bar{
	height: 5px;
	background: white;
}

.project-card{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;

}
.project-details{
	max-width: 1080px;
	height: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}
.project-img{
	flex: 1;
	height: 50%;
	width: 50%;
	display: flex;
	align-items: center;
}
.project-img>img{
	object-fit: cover;
	height: 100%;
	width: 100%;
	align-self: center;
}

.project-text{
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.project-text>h2{
	font-size: 34px;
}
.project-text>p{
	color: gray;
	font-size: 20px;
}
.project-text>button{
	padding: 10px;
	border: none;
	border-radius: 10px;
	width: 110px;
	background-color: orange;
	cursor: pointer;
	font-weight: 500;
}
@media screen and (max-width: 767px){
	.progress{
		font-size: 12px;
		margin: 0 0 5px 0;
		line-height: 1;
	}
	.progress-bar{
		height: 3.3px;
		border-radius: 4px 20px 4px 20px;
		margin-top: 11px;
	}
	.project-details{
		flex-wrap: wrap;
		gap: 15px;
	}
	.project-text{
		font-family: Roboto, serif;

		h2{
			font-size: 14px;
			letter-spacing: 1px;
		}

		p{
			font-size: 12px;
			text-overflow: ellipsis;
		}
		button{
			width: 78px;
			height: 34px;
		}
	}
}