.contacts{
	height: 100%;
	max-width: 1080px;
	margin: auto;
	display: flex;
	align-items: center;
	gap: 23px;
}

.contact-details{
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 23px;
}
.contact-details>h1{
	font-size: 70px;
	/* line-height: 27px; */
}
.contact-details span{
	font-weight: 300;
}
.form-container{
	flex: 1;
	position: relative;
}
.phone-svg{
	width: 450px;
	height: 450px;
	/* width="450px" height="450px" */
	position: absolute;
	stroke: orange;
	margin: auto;
	z-index: -1;
}
.form-container>form{
	display: flex;
	flex-direction: column;
	gap: 23px;
}
.form-container input,textarea{
	padding: 10px;
	border: solid 1.3px white;
	border-radius: 5px;
	background: transparent;
	color: white;
}
/* textarea{
	padding: 10px;
	background: transparent;
	border: solid 1.3px white;

} */

.form-container button{
	width: 120px;
	padding: 13px;
	border: none;
	background-color: orange;
	border-radius: 10px;
	cursor: pointer;
	font-weight: 600;
	margin-bottom: -5px;
}
.error{
	color: #f44440;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.msg{
	font-family: Roboto, 'Arial Narrow', Arial, sans-serif;
	color: #1f3;
}
@media screen and (max-width: 767px){
	.contacts{
		flex-direction: column;
		align-items: center;
	}
	.contact-details{
		align-self: center;
		text-align: center;
		font-family: 'Haettenschweiler', 'Arial Narrow Bold',;

		h1{
			font-size: 24px;
		}
		span{
			font-size: 15px;
			font-style: italic;
		}
	}

	.phone-svg{
		width: 180px;
		height: 180px;
		top: 20%;
		left: 13%;
	}
}