.hero {
	height: calc(100vh - 10vh);
	background: linear-gradient(180deg, #0c0c1d, #111132);
	overflow: hidden;
	position: relative;

	.wrapper {
		max-width: 1060px;

		.text-container {
			height: 100%;
			width: 50%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: 19px;

			h2 {
				font-size: 30px;
				color: rebeccapurple;
				letter-spacing: 10px;
			}

			h1 {
				font-size: 60px;
			}
		}
		.image-container {
			/* display: none; */
			height: 100%;
			width: auto;
			position: absolute;
			top: 0;
			right: 0;

			img {
				height: 100%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.hero {
		.wrapper {
			display: flex;
			flex-flow: column nowrap;

			.text-container{
				padding-top: 10vh;
				width: 100%;

				h2{
					font-size: 15px;
				}

				h1{
					font-size: 35px;
				}
			}

			.image-container{
				position: relative;
				height: 50%;
			}

		}
	}
}


.hero-btns {

	.hero-button {
		padding: 12px;
		border: solid 1px white;
		border-radius: 9px;
		background: transparent;
		color: white;
		margin-right: 19px;
		cursor: pointer;
		font-weight: 500;
	}
}

.text-container>.scroll-img {
	width: 50px;
}

.sliding-text {
	width: 50%;
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 50vh;
	font-weight: bold;
	white-space: nowrap;
	color: #ffffff09;
}

