.parallax{
	width: 100%;
	height: 100%;
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	h1{
		font-size: 100px;
	}
}


.mountains{
	width: 100%;
	height: 100%;
	position: absolute;
	background-image: url("/public/images/mountains.png");
	background-size: cover;
	background-position: bottom;
	z-index: 3;
}

.planets{
	width: 100%;
	height: 100%;
	position: absolute;
	background-image: url("/public/images/planets.png");
	background-size: cover;
	background-position: bottom;
	z-index: 2;
}

.stars{
	width: 100%;
	height: 100%;
	position: absolute;
	background-image: url("/public/images/stars.png");
	background-size: cover;
	background-position: bottom;
	z-index: 1;
}

@media screen and (max-width: 767px){
	.parallax{
		overflow: hidden;
		h1{
			font-family: 'Cartograph CF',cursive;
			font-size: 33px;
		}
	}
	.mountains{
		width: 100%;
		background-repeat: no-repeat;
	}
	.planets{
		background-size:unset;
		background-repeat: no-repeat;
	}
}