
/* learned new thing about scroll */
html{
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0c0c1d;
  color: lightgrey;
  scrollbar-width: none;
}

a{
  text-decoration: none;
  color: inherit;
  font-family: 'Cartograph CF';
  font-weight: 500;
}

section{
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
  overflow: hidden;
}
#Contact{
  height: auto;
}