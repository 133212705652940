.services{
	background: linear-gradient(180deg, #0c0c1d, #111132);
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.services-text-container{
	font-family: 'Courier New', Courier, monospace;
	align-self: flex-end;
	display: flex;
	align-items: center;
	gap: 20px;
	padding: 13px;
}
.services-text-container>hr{
	width: 350px;
	border: none;
	border-top: solid 0.5px grey;
}
.services-text-container>p{
	font-weight: 400;
	font-size: 15px;
	color: grey;
	text-align: right;
}

.services-title{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.services-title>.title{
	display: flex;
	align-items: center;
	gap: 50px;
}
.services-title>.title>h1{
	font-size: 36px;
	font-weight: 100;
}
.services-title>.title>button{
	width: 170px;
	height: 67px;
	border-radius: 50px;
	background-color: orange;
	border: none;
	font-size: 17px;
	font-weight: 700;
	cursor: pointer;
}
.services-title>.title>img{
	width: 300px;
	height: 115px;
	border: none;
	border-radius: 50px;
	object-fit: fill;
	object-position: center;
}
.services-list{
	list-style-type: none;
	display: flex;
	flex-flow: row wrap;
	max-width: 1080px;
	margin: auto;
	border: solid 1px #444;
	height: 40%;
}

.skill-item{
	list-style-type: none;
	width: 98px;
	height: 98px;
	border-radius: 5px;

	.skill-img{
		width: 80%;
		img{
			width: 100%;
			object-fit: contain;
		}
}
}
@media screen and (max-width: 767px){
	.services{
		gap: 23px;
	}
	.services-text-container{
		width: 90%;

		.text-container{
			width: 100%;
		}

		hr{
			width: 45%;
		}

		p{
			font-size: 9px;
			font-family:   'Cartograph CF',ans-serif;
		}
	}

	.services-title{
		.title:last-child{
			display: none;
		}
		.title{
			flex-direction: column;
			text-align: center;
			gap: 0;

			img{
				width: 290px;
			}
		}
	}
}